import React, { Component } from "react";

class GuestLabel extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let guestInfoStyle = {
      padding: "20px",
      paddingBottom: "10px",
      position: "absolute",
      top: "350px",
      left: "0px",
      color: "#d8d9da",
      fontSize: "26px",
      background: "rgb(45, 70, 92)",
      background: "linear-gradient(315deg, #3c607f, #213546)",
      border: "2px solid black"
    };

    let guestWrapper = {};

    if (this.props.show) {
      guestInfoStyle.display = "block";
    } else {
      guestInfoStyle.display = "none";
    }

    return (
      <div style={guestInfoStyle}>
        <div>
          <img
            src={this.props.img}
            style={{ float: "left", height: "175px" }}
          />
          <p style={{ float: "right", paddingLeft: "30px", marginTop: "0px" }}>
            <strong style={{ color: "#FFFFFF" }}>Guest Info: </strong>
            <br />
            <hr />
            <strong>Name: </strong>
            {this.props.name}
            <br />
            <strong>Nationality: </strong>
            {this.props.nat}
            <br />
            <strong>Age: </strong>
            {this.props.age} years
            <br />
          </p>
        </div>
      </div>
    );
  }
}

export default GuestLabel;
