import * as THREE from "three";

class RandomGuests {
	constructor(scene, position) {
		this.scene = scene;
		this.guestData = false;
		this.getRandomData = this.getRandomData.bind(this);
		this.getGuest = this.getGuest.bind(this);
		this.getRandomData();
	}

	getRandomData() {
		var xmlhttp = new XMLHttpRequest();
		var _this = this;
	    xmlhttp.onreadystatechange = function() {
	        if (xmlhttp.readyState == XMLHttpRequest.DONE) { 
	           if (xmlhttp.status == 200) {
	               _this.guestData = JSON.parse(xmlhttp.responseText);
	               console.log(_this.guestData);
	           }
	           else if (xmlhttp.status == 400) {
	              alert('No Guest data availalbe');
	           }
	           else {
	               alert('No Guest data availalbe');
	           }
	        }
	    };

	    xmlhttp.open("GET", "https://randomuser.me/api?results=1000", true);
	    xmlhttp.send();
	}

	getGuest(id) {
		if (this.guestData && this.guestData.results && this.guestData.results[id]) {
			console.log(this.guestData.results[id]);
			return this.guestData.results[parseInt(id)];	
		}
	}
}

export default RandomGuests;