import * as THREE from "three";

class RandomAlarms {
	constructor(props) {
		this.cabins = props.cabins;
		this.generateRandomAndScheduleNext = this.generateRandomAndScheduleNext.bind(this);
		this.generateAlarm = this.generateAlarm.bind(this);
		this.getRandomCabin = this.getRandomCabin.bind(this);
		this.getRandomProblem = this.getRandomProblem.bind(this);
		this.callback = props.alarmCallback;
		//this.generateRandomAndScheduleNext();			
	}

	generateRandomAndScheduleNext() {
		setTimeout(this.generateRandomAndScheduleNext, 30000);
		this.generateAlarm();
	}

	generateAlarm(state) {
		let cabinNumber = this.getRandomCabin();
		let alarmData = {
			name: state === 2 ? this.getRandomProblem(cabinNumber) : this.getRandomNotification(cabinNumber),
			cabin: cabinNumber,
			state: state,
			time: new Date().toLocaleString(),
			alarmId: (new Date().getTime() + "") + Math.round(Math.random()*1000)
		}
		console.log(alarmData);

		this.callback(alarmData);
	}

	getRandomCabin() {
		let cabin = parseInt((Math.random() * 140 ));
		let deck = (parseInt((Math.random() * 5 )) + 6) * 1000;
		return (cabin + deck);
	}

	getRandomProblem(cabinNumber) {
		const descriptions = [
			"Co2 Sensor defective in cabin " + cabinNumber,
			"Lux sensor defective in cabin  " + cabinNumber
		];

		let rand = parseInt(Math.random() * 0.99 * descriptions.length);
		return descriptions[rand];
	}

	getRandomProblem(cabinNumber) {
		const descriptions = [
			"Co2 Sensor defective in cabin " + cabinNumber,
			"Lux sensor defective in cabin  " + cabinNumber
		];

		let rand = parseInt(Math.random() * 0.99 * descriptions.length);
		return descriptions[rand];
	}

	getRandomNotification(cabinNumber) {
		const descriptions = [
			"Window possibly stuck in cabin " + cabinNumber,
			"Poor air quality in cabin " + cabinNumber,
			"Balcony door stuck in cabin " + cabinNumber,
		];

		let rand = parseInt(Math.random() * 0.99 * descriptions.length);
		return descriptions[rand];
	}
}

export default RandomAlarms;


