import React, { Component } from "react";
import * as THREE from "three";
import * as OBJLoader from "three-obj-loader";
import DeckPlane from "./MapSelector3D/DeckPlane";
import CabinLabel from "./MapSelector3D/CabinLabel";
import CabinSelectorCamera from "./MapSelector3D/CabinSelectorCamera";
import GuestLabel from "./MapSelector3D/GuestLabel";
import RandomGuests from "./MapSelector3D/RandomGuests";
import AlarmList from "./MapSelector3D/AlarmList";
import RandomAlarms from "./MapSelector3D/RandomAlarms";
import ColorBlinker from "./MapSelector3D/ColorBlinker";

//Local Grafana URL 192.168.56.101:3000

var OrbitControls = require("three-orbit-controls")(THREE);
OBJLoader(THREE);

const alertMaterial = new THREE.MeshBasicMaterial({
  color: "#FF3333",
  opacity: 0.5,
  transparent: true
});
const notificationMaterial = new THREE.MeshBasicMaterial({
  color: "#FFFF33",
  opacity: 0.5,
  transparent: true
});
const highlightMaterial = new THREE.MeshBasicMaterial({
  color: "#00FF00",
  opacity: 0.4,
  transparent: true
});
const basicMaterial = new THREE.MeshBasicMaterial({
  color: "#00FF00",
  opacity: 0.2,
  transparent: true
});
const selectionMaterial = new THREE.MeshBasicMaterial({
  color: "#33FF33",
  opacity: 0.6,
  transparent: true
});
const alertSelectionMaterial = new THREE.MeshBasicMaterial({
  color: "#FF0000",
  opacity: 0.7,
  transparent: true
});
const notificationSelectionMaterial = new THREE.MeshBasicMaterial({
  color: "#FFFF00",
  opacity: 0.7,
  transparent: true
});

/*
const alertMaterial = new THREE.MeshBasicMaterial({
  color: "#FF3333",
  opacity: 0.5,
  transparent: true
});
const notificationMaterial = new THREE.MeshBasicMaterial({
  color: "#FFFF33",
  opacity: 0.5,
  transparent: true
});
const highlightMaterial = new THREE.MeshBasicMaterial({
  color: "#AAAAFF",
  opacity: 0.65,
  transparent: true
});
const basicMaterial = new THREE.MeshBasicMaterial({
  color: "#7788FF",
  opacity: 0.3,
  transparent: true
});
const selectionMaterial = new THREE.MeshBasicMaterial({
  color: "#7777FF",
  opacity: 0.85,
  transparent: true
});
const alertSelectionMaterial = new THREE.MeshBasicMaterial({
  color: "#FF0000",
  opacity: 0.7,
  transparent: true
});
const notificationSelectionMaterial = new THREE.MeshBasicMaterial({
  color: "#FFFF00",
  opacity: 0.7,
  transparent: true
});

*/

const GRAFANA_URL = {
  cabin:
    "https://grafana.loisto.pro/d/zST14xqmz/loisto-miami?orgId=2&kiosk&refresh=5s",
  main:
    "https://grafana.loisto.pro/d/zT0rDSemz/loisto-miami-decks?orgId=2&from=now-2d&to=now&kiosk&refresh=5s"
};

//Local grafana. Also add to package.json: "homepage": "https://192.168.56.101/showroom-miami-html-2019/",
/*
const GRAFANA_URL = {
  cabin: "https://192.168.56.101:3000/d/zST14xqmz/loisto-miami?orgId=2&from=1554680840479&to=1554713600741&kiosk",
  main:  "https://192.168.56.101:3000/d/zT0rDSemz/loisto-miami-decks?orgId=2&from=1554728713314&to=1554813188874&kiosk"
}
*/

//const GRAFANA_URL = "https://192.168.56.101:3000/d/zST14xqmz/loisto-miami?orgId=2&from=1553497421284&to=1553529307847&kiosk";

class MapSelector3D extends Component {
  constructor(props) {
    super(props);
    this.state = {
      guestData: {},
      cabin: false,
      hideTag: false,
      alarms: []
    };

    this.initDecks = this.initDecks.bind(this);
    this.getLabelPosition = this.getLabelPosition.bind(this);
    this.getScreenPosition = this.getScreenPosition.bind(this);
    this.handleKey = this.handleKey.bind(this);
    this.setMousePos = this.setMousePos.bind(this);
    this.onAlarmClicked = this.onAlarmClicked.bind(this);
    this.selectAndZoomToCabin = this.selectAndZoomToCabin.bind(this);
    this.alarmGenerated = this.alarmGenerated.bind(this);
    this.delayedFrameRequest = this.delayedFrameRequest.bind(this);
    this.clearAlarm = this.clearAlarm.bind(this);
    this.clearCabinSelection = this.clearCabinSelection.bind(this);
    this.showCabinNumberLabelDelayed = this.showCabinNumberLabelDelayed.bind(
      this
    );
    this.hideCabinNumberLabel = this.hideCabinNumberLabel.bind(this);
    this.showCabinNumberLabel = this.showCabinNumberLabel.bind(this);
  }

  componentDidMount() {
    this.guests = new RandomGuests();
    this.THREE = THREE;

    const width = this.mount.clientWidth;
    const height = this.mount.clientHeight;

    //ADD SCENE
    this.scene = new THREE.Scene();

    this.cabinCamera = new CabinSelectorCamera(this.scene, width, height);
    this.camera = this.cabinCamera.camera; //Get reference to the THREEJS wrapper

    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    //this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.renderer.setClearColor(0x000000, 0);
    this.renderer.setSize(width, height);
    this.mount.appendChild(this.renderer.domElement);

    this.cabinsObjects = [];

    //Test lighting
    this.light = new THREE.DirectionalLight(0xffffff, 1);
    this.light.target.position.x = -10;
    this.scene.add(this.light);
    this.scene.add(this.light.target);

    //Grid helper
    var size = 100;
    var divisions = 10;
    var gridHelper = new THREE.GridHelper(size, divisions);
    //this.scene.add( gridHelper );

    // instantiate a loader
    const objLoader = new this.THREE.OBJLoader();

    let _this = this;

    const material = new THREE.MeshStandardMaterial({
      color: "#0f0fff",
      side: THREE.DoubleSide
    });
    //const material = new THREE.MeshBasicMaterial({ color: "#ff0000" });

    // Create a Cube Mesh with basic material
    var g = new THREE.BoxGeometry(2, 2, 2);
    var m = new THREE.MeshBasicMaterial({ color: "#433F81" });
    this.companionCube = this.getCube();

    // Add cube to Scene
    this.scene.add(this.companionCube);

    this.cabinMeshes = [];

    let url = "";
    //let url = "http://10.15.3.197:3000"

    objLoader.load(
      // resource URL
      url + "models/floor2.obj",
      function(obj) {
        _this.cabinMeshes[0] = obj.children[0].geometry;
        objLoader.load(url + "models/floor3.obj", function(obj2) {
          _this.cabinMeshes[1] = obj2.children[0].geometry;
          objLoader.load(url + "models/floor5.obj", function(obj3) {
            _this.cabinMeshes[2] = obj3.children[0].geometry;
            _this.initDecks();
          });
        });
      }
    );

    //Cabin label
    this.labelIndication = new CabinLabel(this.scene);

    this.raycaster = new THREE.Raycaster();

    //Hightlighting
    this.lastHighlightMesh = false;
    this.selectedMesh = false;

    this.start();

    this.labelElement = document.getElementById("cabinLabel");
    this.deckLabels = document.getElementsByClassName("deckNameLabel");
    this.cabinNumberTag = document.getElementById("cabinNumberTag");
    this.logoImg = document.getElementById("loisto_logo");

    this.lastMouseMove = false;

    //Alert blinker
    this.alertBlinker = new ColorBlinker(
      new THREE.Color(0xff0000),
      new THREE.Color(0xffffff),
      0.2
    );

    //Fast fix for delaying label showing after zoom
    this.showCabinNumberTimeoutToken = false;
    this.showCabinNumberOnNextMove = false;
  }

  initDecks() {
    //Testing plane
    var testTexture = new THREE.TextureLoader().load("img/decks/10_trans.png");
    var testTexture2 = new THREE.TextureLoader().load(
      "img/decks/10_trans_b.png"
    );

    let dp = new DeckPlane(this.scene, 0, testTexture2, this.cabinMeshes, 6000);
    let dp2 = new DeckPlane(
      this.scene,
      200,
      testTexture2,
      this.cabinMeshes,
      7000
    );
    let dp3 = new DeckPlane(
      this.scene,
      400,
      testTexture2,
      this.cabinMeshes,
      8000
    );
    let dp4 = new DeckPlane(
      this.scene,
      600,
      testTexture2,
      this.cabinMeshes,
      9000
    );
    let dp5 = new DeckPlane(
      this.scene,
      800,
      testTexture,
      this.cabinMeshes,
      10000
    );

    this.cabins = dp.getCabinMeshes();

    this.cabins = this.cabins.concat(dp2.getCabinMeshes());
    this.cabins = this.cabins.concat(dp3.getCabinMeshes());
    this.cabins = this.cabins.concat(dp4.getCabinMeshes());
    this.cabins = this.cabins.concat(dp5.getCabinMeshes());

    this.cabinsById = [];
    for (let c of this.cabins) {
      this.cabinsById[c.cabinNumber] = c;
    }

    //Alarms
    this.alarmGenerator = new RandomAlarms({
      cabins: this.cabinsById,
      alarmCallback: this.alarmGenerated
    });
  }

  // Converts from degrees to radians.
  toRadians(degrees) {
    return (degrees * Math.PI) / 180;
  }

  // Converts from radians to degrees.
  toDegrees(radians) {
    return (radians * 180) / Math.PI;
  }

  printCameraSettings() {
    let cameraSettings = {};
    cameraSettings.fov = this.camera.fov;
    cameraSettings.aspect = this.camera.aspect;
    cameraSettings.near = this.camera.near;
    cameraSettings.far = this.camera.far;
    cameraSettings.position = this.camera.position;
    cameraSettings.rotation = this.camera.rotation;
    console.log(JSON.stringify(cameraSettings, null, 2));
  }

  testRays(e) {
    if (!this.cabins || !e) {
      return;
    }

    // e = Mouse click event.
    var rect = document.getElementById("threeDrawArea").getBoundingClientRect();
    var x = e.clientX - rect.left; //x position within the element.
    var y = e.clientY - rect.top; //y position within the element.

    var size = this.renderer.getSize(new THREE.Vector2());
    var mouse = {};
    mouse.x = 2 * (x / size.width) - 1;
    mouse.y = 1 - 2 * (y / size.height);

    this.raycaster.setFromCamera(mouse, this.camera);
    var intersects = this.raycaster.intersectObjects(this.cabins, true);

    if (intersects.length > 0) {
      if (this.lastHighlightMesh) {
        this.lastHighlightMesh.material = this.getBasicMaterial(
          this.lastHighlightMesh.alert
        );
      }

      if (!intersects[0].object.alert) {
        intersects[0].object.material = highlightMaterial;
      }

      this.lastHighlightMesh = intersects[0].object;
    } else {
      if (this.lastHighlightMesh && !this.lastHighlightMesh.alert) {
        this.lastHighlightMesh.material = basicMaterial;
      }
      this.lastHighlightMesh = false;
    }

    if (this.selectedMesh) {
      this.selectedMesh.material = this.getSelectionMaterial(
        this.selectedMesh.alert
      );
    }

    if (this.lastHighlightMesh) {
      //Move label

      this.labelIndication.moveTo(this.lastHighlightMesh.labelPosition);
      let screenPos = this.getScreenPosition(
        this.labelIndication.mesh,
        this.camera,
        false
      );
      this.labelElement.style.top = parseInt(screenPos.y) + "px";
      this.labelElement.style.left = parseInt(screenPos.x) + "px";
      this.cabinNumberTag.innerHTML = this.lastHighlightMesh.cabinNumber + "";
      //console.log(screenPos);
    } else {
      this.cabinNumberTag.innerHTML = "";
    }

    if (this.showCabinNumberOnNextMove) {
      this.showCabinNumberOnNextMove = false;
      this.showCabinNumberLabel();
    }
  }

  alarmGenerated(alarmData) {
    console.log("Got alarms!");
    let alarms = this.state.alarms;
    alarms.push(alarmData);
    this.setState({ alarms });

    //Set mesh color
    this.cabinsById[alarmData.cabin].alert = alarmData.state;
    this.cabinsById[alarmData.cabin].material = this.getBasicMaterial(
      alarmData.state
    );
  }

  getBasicMaterial(alertState) {
    if (alertState === 2) {
      return alertMaterial;
    } else if (alertState === 1) {
      return notificationMaterial;
    }
    return basicMaterial;
  }

  getSelectionMaterial(alertState) {
    if (alertState === 2) {
      return alertSelectionMaterial;
    } else if (alertState === 1) {
      return notificationSelectionMaterial;
    }
    return selectionMaterial;
  }

  clearAlarm(alarmId, cabinId) {
    let alarms = this.state.alarms;
    let removeIndex = alarms
      .map(function(a) {
        return a.alarmId;
      })
      .indexOf(alarmId);
    ~removeIndex && alarms.splice(removeIndex, 1);

    //Check if there are ongoing alarms
    let alarmsLeft = alarms.filter(obj => {
      return obj.cabin === cabinId;
    });

    let stateLeft = 0;
    for (let a of alarmsLeft) {
      if (a.state > stateLeft) {
        stateLeft = a.state;
      }
    }

    this.cabinsById[cabinId].alert = stateLeft;
    this.cabinsById[cabinId].material = this.getBasicMaterial(stateLeft);

    this.setState({ alarms });

    this.cabinNumberTag.innerHTML = "";
  }

  handleClick(e) {
    if (this.selectedMesh) {
      this.selectedMesh.material = this.getBasicMaterial(
        this.selectedMesh.alert
      );
      this.selectedMesh = false;
    }

    if (this.lastHighlightMesh) {
      this.selectedMesh = this.lastHighlightMesh;

      this.selectedMesh.material = selectionMaterial;
      //alert(this.selectedMesh.labelPosition);
      document.getElementById("cabinName").innerHTML =
        this.selectedMesh.name + " - Loisto Demo Cabin in Finland";
      //
      document.getElementById("grafanaFrame").src = GRAFANA_URL.cabin;
      //console.log(this.selectedMesh);
      //alert("hello");

      this.cabinCamera.lookAt(this.lastHighlightMesh);

      this.setState({
        guestData: this.guests.getGuest(this.lastHighlightMesh.guestId),
        cabin: parseInt(this.selectedMesh.cabinNumber)
      });
      this.hideCabinNumberLabel();
    } else {
      this.clearCabinSelection();
    }
    this.lastHighlightMesh = false;
  }

  showCabinNumberLabelDelayed(delayTime) {
    if (this.showCabinNumberTimeoutToken) {
      clearTimeout(this.showCabinNumberTimeoutToken);
    }
    let _this = this;
    this.showCabinNumberTimeoutToken = setTimeout(function() {
      _this.showCabinNumberOnNextMove = true;
      _this.showCabinNumberTimeoutToken = false;
      for (let l of _this.deckLabels) {
        l.style.opacity = 1.0;
      }
      _this.logoImg.style.opacity = 1.0;
    }, delayTime);
  }

  hideCabinNumberLabel() {
    if (this.showCabinNumberTimeoutToken) {
      clearTimeout(this.showCabinNumberTimeoutToken);
    }
    this.labelElement.style.display = "none";
    for (let l of this.deckLabels) {
      l.style.opacity = 0.0;
    }
    this.logoImg.style.opacity = 0.0;
  }

  showCabinNumberLabel() {
    this.labelElement.style.display = "block";
  }

  selectAndZoomToCabin(cabinId) {
    if (!this.cabinsById[cabinId]) {
      return;
    }

    this.selectedMesh = this.cabinsById[cabinId];

    this.selectedMesh.material = this.getSelectionMaterial(
      this.selectedMesh.alert
    );

    document.getElementById("cabinName").innerHTML =
      this.selectedMesh.name + " - Loisto Demo Cabin in Finland";
    //      document.getElementById('grafanaFrame').src = "https://dev-ubuntu18.loisto.pro:3000/d/zST14xqmz/loisto-miami?orgId=2&refresh=5s&kiosk";
    document.getElementById("grafanaFrame").src = GRAFANA_URL.cabin;
    this.cabinCamera.lookAt(this.selectedMesh);

    this.setState({
      guestData: this.guests.getGuest(this.selectedMesh.guestId),
      cabin: parseInt(this.selectedMesh.cabinNumber)
    });
    this.hideCabinNumberLabel();
  }

  getCube() {
    //Create deck label
    var g = new THREE.BoxGeometry(1, 1, 1);
    var m = new THREE.MeshBasicMaterial({
      color: "#FFFFFF",
      side: THREE.DoubleSide,
      depthTest: false
    });
    var o = new THREE.Mesh(g, m);
    return o;
  }

  getLabelPosition(obj) {
    let offset = { x: 0, y: 30 };
    return this.getScreenPosition(obj, this.camera, offset);
  }

  getScreenPosition(obj, camera, offset) {
    var vector = new THREE.Vector3();

    var widthHalf = 0.5 * this.renderer.context.canvas.width;
    var heightHalf = 0.5 * this.renderer.context.canvas.height;

    obj.updateMatrixWorld();
    vector.setFromMatrixPosition(obj.matrixWorld);

    if (offset) {
      vector.x += offset.x;
      vector.y += offset.y;
    }

    vector.project(camera);

    vector.x = vector.x * widthHalf + widthHalf;
    vector.y = -(vector.y * heightHalf) + heightHalf;

    return {
      x: vector.x,
      y: vector.y
    };
  }

  componentWillUnmount() {
    this.stop();
    this.mount.removeChild(this.renderer.domElement);
  }

  handleKey(e) {
    e.preventDefault();
    switch (e.keyCode) {
      case 27:
        this.clearCabinSelection();
        break;
      case 49:
        this.alarmGenerator.generateAlarm(1);
        break;
      case 50:
        this.alarmGenerator.generateAlarm(2);
        break;
    }
  }

  start = () => {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate);
    }
  };

  stop = () => {
    cancelAnimationFrame(this.frameId);
  };

  animate = () => {
    this.cabinCamera.update();
    alertMaterial.color.set(this.alertBlinker.update());

    //console.log(this.renderer.getMaxAnisotropy());
    if (this.camera) {
      //this.printCameraSettings();
    }

    //setTimeout(this.delayedFrameRequest, 1000 / 30 );
    this.delayedFrameRequest();
  };

  delayedFrameRequest() {
    this.renderScene();
    this.frameId = window.requestAnimationFrame(this.animate);
  }

  renderScene = () => {
    this.renderer.render(this.scene, this.camera);
  };

  upperCaseFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  setMousePos(e) {
    this.lastMouseMove = e;
  }

  onAlarmClicked(cabinId) {
    this.clearCabinSelection();
    // alert("cliked " + cabinId);
    this.selectAndZoomToCabin(cabinId);
  }

  clearCabinSelection() {
    if (!this.state.cabin) {
      return;
    }

    this.setState({
      cabin: false
    });
    this.cabinCamera.backTo2D();
    document.getElementById("grafanaFrame").src = GRAFANA_URL.main;
    document.getElementById("cabinName").innerHTML = "General Statistics";
    if (this.selectedMesh) {
      this.selectedMesh.material = this.getBasicMaterial(
        this.selectedMesh.alert
      );
    }
    this.selectedMesh = false;

    if (this.lastHighlightMesh) {
      this.lastHighlightMesh.material = this.getBasicMaterial(
        this.selectedMesh.alert
      );
    }
    this.lastHighlightMesh = false;

    this.showCabinNumberLabelDelayed(3000);
  }

  render() {
    let headingStyle = {
      color: "#d8d9da",
      position: "relative",
      fontSize: "60px",
      height: "60px",
      padding: "10px 40px",
      fontWeight: "normal",
      paddingTop: "30px",
      width: "1870px",
      border: "1px solid #24384a",
      background: "#161f31",
      borderRadius: "3px"
    };

    let guestName = "no data available";
    let guestImg = "";
    let guestNationality = "no data available";
    let guestAge = "no data available";
    let cabinId = false;
    let cabinLabelStyle = {
      display: "block",
      position: "absolute",
      top: 100,
      left: -100,
      width: "0px",
      textAlign: "center"
    };

    //console.log(this.state);
    try {
      if (this.state && this.state.guestData) {
        guestName =
          this.upperCaseFirst(this.state.guestData.name.first) +
          " " +
          this.upperCaseFirst(this.state.guestData.name.last) +
          " (" +
          this.state.guestData.name.title +
          ")";
        guestImg = this.state.guestData.picture.large;
        guestNationality = this.state.guestData.nat;
        guestAge = this.state.guestData.dob.age;
        cabinId = this.state.cabin;
      }
    } catch (e) {}

    let logoStyle = {
      position: "absolute",
      top: "110px",
      left: "650px",
      width: "350px",
      transitionDuration: "1.25s",
      transitionProperty: "opacity",
      display: "none"
    };

    let havilaStyle = {
      position: "absolute",
      top: "135px",
      left: "0px",
      width: "288px",
      height: "40px",
      textAlign: "center"
    };

    let cornerStyle = {
      position: "absolute",
      top: "0px",
      left: "0px",
      width: "200px",
      opacity: 0.0,
      transitionDuration: "1s",
      transitionProperty: "opacity"
    };

    if (this.state.cabin) {
      cornerStyle.opacity = 0.0;
    } else {
      cornerStyle.opacity = 0.0;
    }

    return (
      <div
        id="threeDrawArea"
        tabIndex="0"
        onMouseMove={e => this.testRays(e)}
        onMouseDown={e => this.handleClick(e)}
        onKeyDown={e => this.handleKey(e)}
        style={{
          width: "2000px",
          height: "2100px",
          position: "relative",
          cursor: "cell",
          outline: "none !important"
        }}
        ref={mount => {
          this.mount = mount;
        }}
      >
        <div id="loisto_logo" style={logoStyle}>
          <img src="img/general_logo.png" />
        </div>

        <img
          id="loisto_logo_corner"
          src="img/logo_corner.png"
          style={cornerStyle}
        />

        <div
          className="deckNameLabel"
          style={{ top: "300px", color: "#ffffff" }}
        >
          DECK 10
        </div>
        <div
          className="deckNameLabel"
          style={{ top: "616px", color: "#ffffff" }}
        >
          DECK 9
        </div>
        <div
          className="deckNameLabel"
          style={{ top: "932px", color: "#ffffff" }}
        >
          DECK 8
        </div>
        <div
          className="deckNameLabel"
          style={{ top: "1248px", color: "#ffffff" }}
        >
          DECK 7
        </div>
        <div
          className="deckNameLabel"
          style={{ top: "1565px", color: "#ffffff" }}
        >
          DECK 6
        </div>

        <GuestLabel
          name={guestName}
          img={guestImg}
          nat={guestNationality}
          age={guestAge}
          show={cabinId}
        />

        <div id="cabinLabel" style={cabinLabelStyle}>
          <div
            id="cabinNumberTag"
            style={{
              color: "#d8d9da",
              position: "absolute",
              top: "-20px",
              left: "-50px",
              width: "100px",
              textAlign: "center"
            }}
          >
            Cabin name
          </div>
        </div>

        <div
          id="backgroundShade"
          style={{
            position: "absolute",
            "padding-top": "150px",
            top: "0%",
            left: "90%",
            width: "120%",
            height: "100%",
            background: "#060f23"
          }}
        >
          <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
            <div id="cabinName" style={headingStyle}>
              General Statistics
            </div>
          </div>
          <iframe
            id="grafanaFrame"
            headingStyle="0"
            width="200"
            height="300"
            src={GRAFANA_URL.main}
            style={{
              position: "relative",
              height: "100%",
              width: "1336px",
              frameborder: 0,
              backgroundColor: "rgba(0,0,0,0)",
              border: "0"
            }}
          />

          <AlarmList
            onClick={this.onAlarmClicked}
            onClearClick={this.clearAlarm}
            alarms={this.state.alarms}
            currentCabin={this.state.cabin}
          />
        </div>
      </div>
    );
  }
}
export default MapSelector3D;
