import React, { Component } from "react";
import SingleAlarm from "./SingleAlarm";

//Alert red #ef5a00
//Notification yellow #dcc725

class AlarmList extends Component {
  constructor(props) {
    super(props);

    this.onAlarmClick = this.onAlarmClick.bind(this);
    this.onClearClick = this.onClearClick.bind(this);
  }

  onAlarmClick(cabinId) {
    this.props.onClick(cabinId);
  }

  onClearClick(alarmId, cabinId) {
    this.props.onClearClick(alarmId, cabinId);
  }

  onHighlight(cabinId) {
    this.props.onClearClick(cabinId);
  }

  onRemoveHighlight(cabinId) {
    this.props.onClearClick(cabinId);
  }

  render() {
    console.log(this.props.alarms);
    const alarms = this.props.alarms.map(data => (
      <SingleAlarm
        onClick={this.onAlarmClick}
        onClearClick={this.onClearClick}
        key={data.alarmId}
        alarmId={data.alarmId}
        name={data.name}
        time={data.time}
        state={data.state}
        cabin={data.cabin}
        mask={this.props.currentCabin && this.props.currentCabin != data.cabin}
      />
    ));

    let alarmListStyle = {
      padding: "50px",
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: "272px",
      left: "1336px",
      padding: "5px",
      margin: "0px",
      width: "624px",
      height: "1583px",
      color: "#ffffff",
      fontWeight: "bold",
      paddingTop: "0px",
      fontSize: "12px",
      textAlign: "center",
      cursor: "pointer",
      overflowY: "auto",
      border: "1px solid #24384a",
      background: "#161f31",
      borderRadius: "3px"
    };

    return (
      <div
        onMouseDown={e => {
          e.stopPropagation();
        }}
        style={alarmListStyle}
      >
        <p style={{ marginBottom: "20px" }}>ALARMS</p>
        {alarms}
      </div>
    );
  }
}

export default AlarmList;
