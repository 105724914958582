import React, { Component } from "react";

class SingleAlarm extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.clearBtnClick = this.clearBtnClick.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseExit = this.onMouseExit.bind(this);
  }

  handleClick(e) {
    this.props.onClick(this.props.cabin);
  }

  clearBtnClick(e) {
    e.stopPropagation();
    this.props.onClearClick(this.props.alarmId, this.props.cabin);
  }

  onMouseEnter(e) {
    console.log("entered " + this.props.cabin);
  }

  onMouseExit(e) {
    console.log("exited " + this.props.cabin);
  }

  render() {
    let singleStyle = {
      background: "linear-gradient(135deg, #3c607f, #213546)",
      color: "#d8d9da",
      fontSize: "20px",
      display: "flex",
      padding: "5px",
      fontSize: "20px",
      height: "75px",
      marginTop: "5px",
      textAlign: "left"
    };

    let iconStyle = {
      fontSize: "40px",
      color: "#ef5a00"
    };

    let resetBtn = {
      widht: "90%",
      color: "rgb(18, 69, 20)",
      borderRadius: "2px",
      border: "2px solid rgb(216, 217, 218)",
      color: "rgb(216, 217, 218)",
      padding: "3px",
      paddingLeft: "10px",
      paddingRight: "10px",
      textAlign: "center",
      marginTop: "10px"
    };

    let alarmIcon = (
      <i style={iconStyle} class="fas fa-exclamation-triangle"></i>
    );

    switch (this.props.state) {
      case 1:
        iconStyle.color = "#dcc725";
        break;
      case 2:
        break;
    }

    let mask = this.props.mask ? (
      <div
        style={{
          position: "absolute",
          width: "96%",
          height: "75px",
          background: "rgba(0,0,0,0.6)"
        }}
      ></div>
    ) : (
      ""
    );
    return (
      <div
        className="singleAlarm"
        style={singleStyle}
        onClick={this.handleClick}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseExit}
      >
        <div style={{ paddingTop: "15px", textAlign: "center", width: "80px" }}>
          {alarmIcon}
        </div>

        <div style={{ width: "400px" }}>
          <strong>{this.props.name}</strong>
          <br />
          {this.props.time}
          <br />
        </div>
        <div onClick={this.clearBtnClick}>
          <div style={resetBtn}>Reset</div>
        </div>
        {mask}
      </div>
    );
  }
}

export default SingleAlarm;
