import React from "react";
import ReactDOM from "react-dom";
import { render } from "react-dom";
import "./index.css";
import App from "./App";
//import ThreeScene from './modules/ThreeScene';
import MapSelector3D from "./modules/MapSelector3D";

//import SideNavigationLink from './SideNavigationLink';

import * as serviceWorker from "./serviceWorker";

import { Router, Route, browserHistory, IndexRoute, Link } from "react-router";

/*
ReactDOM.render(
	<Router history={browserHistory}><App /></Router>, document.getElementById('root'));
*/
// ...
render(
  <div
    style={{
      position: "relative",
      width: "100%",
      height: "1000px"
    }}
  >
    <div
      style={{
        backgroundColor: "#2D465C",
        position: "absolute",
        width: "200%",
        height: "80px",
        zIndex: 1000000
      }}
    >
      <img
        src="img/hub-lg.png"
        style={{ width: "300px", marginLeft: "30px", marginTop: "15px" }}
      ></img>
      <div
        style={{
          width: "800px",
          position: "absolute",
          left: "2950px",
          top: "22px",
          fontSize: "30px",
          textAlign: "right",
          fontWeight: "bold",
          letterSpacing: "1.5px"
        }}
      >
        CRUISE LINE AND PROJECT INFORMATION HERE
      </div>
    </div>
    <MapSelector3D />

    <div
      style={{
        backgroundColor: "#2D465C",
        position: "absolute",
        top: "2100px",
        width: "100%",
        height: "400px",
        zIndex: 1000000,
        paddingTop: "15px",
        fontSize: "26px",
        textAlign: "center",
        fontWeight: "bold",
        letterSpacing: "3px"
      }}
    >
      LOISTO PRO OY &#8226; info@loisto.pro &#8226; +358 400 0826
    </div>
  </div>,

  document.getElementById("root")
);
//Side navigation

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
