import React, { Component } from "react";
//Alert red #ef5a00
//Notification yellow #dcc725

class ColorBlinker {
	constructor(startColor, endColor, speed) {
		this.startColor = startColor;
		this.endColor = endColor;
		this.speed = speed;
		this.sinVal = 0;
	}

	update() {
		this.sinVal += this.speed;
		let col = this.startColor.clone();
		let lerpVal = (Math.sin(this.sinVal)+1.0) * 0.5;
		col.lerp(this.endColor, lerpVal);
		return col;
	}
}

export default ColorBlinker;