import * as THREE from "three";

class CabinLabel {
	constructor(scene) {
		const material = new THREE.MeshBasicMaterial( { color: "#FFFFFF", transparent:true, opacity:0} );
		var geometry = new THREE.BoxGeometry( 10, 10, 10 );
		this.mesh = new THREE.Mesh( geometry, material );
		scene.add(this.mesh);
	}

	moveTo(position) {
		this.mesh.position.x = position.x;	
		this.mesh.position.y = position.y;	
		this.mesh.position.z = position.z;
	}


}

export default CabinLabel;