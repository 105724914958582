import * as THREE from "three";
import * as OBJLoader from 'three-obj-loader';

var OrbitControls = require('three-orbit-controls')(THREE)
OBJLoader(THREE);

class CabinSelectorCamera {
	constructor(scene, clientWidth, clientHeight) {
		this.THREE = THREE;
		this.positionState = "2D"; // "2D", "Cabin"
		this.scene = scene;
		this.lookAtPosition = new THREE.Vector3(50,400,0);
		this.targetRotation = new THREE.Quaternion();
		this.targetPosition = new THREE.Euler (
	          50.0,
	          400,
	          1150,
	          "XYZ"
	    );

	    this.originalPosition = this.targetPosition.clone();

		const material = new THREE.MeshBasicMaterial( { color: "#FFFFFF", transparent:true, opacity:0} );
		var geometry = new THREE.BoxGeometry( 10, 10, 10 );
		this.fakeObj = new THREE.Mesh( geometry, material );
		scene.add(this.fakeObj);
		//Create camera object with default parameters	
		/*
	    this.cameraSettings_3D = {
	      "fov": 60,
	      "aspect": clientWidth / clientHeight,
	      "near": 1,
	      "far": 10000,

	      "position": new THREE.Euler (
	          450.82660807878284,
	          434.494514828334,
	          1155.0479550337695,
	          "XYZ"
	      ),

	      "rotation": new THREE.Euler (
	        -0.014739928873937628,
	        0.01666591258216029,
	        0.0002456607809006635,
	        "XYZ"
	      )
	    }
	    */
	     this.cameraSettings_3D = {
	      "fov": 60,
	      "aspect": clientWidth / clientHeight,
	      "near": 1,
	      "far": 10000,

	      "position": new THREE.Euler (
	          0.0,
	          0,
	          1100,
	          "XYZ"
	    	),

	      "rotation": new THREE.Euler (
	        0,
	        0,
	        0,
	        "XYZ"
	      )
	    }

	    this.cameraSettings = this.cameraSettings_3D;
	        
	    this.camera = new THREE.PerspectiveCamera(
	      this.cameraSettings.fov, 
	      this.cameraSettings.aspect, 
	      this.cameraSettings.near, 
	      this.cameraSettings.far
	    );

	    this.camera.position.x = this.cameraSettings.position.x;
	    this.camera.position.z = this.cameraSettings.position.z;
	    this.camera.position.y = this.cameraSettings.position.y;
	    this.camera.rotation.x = this.cameraSettings.rotation.x;
	    this.camera.rotation.z = this.cameraSettings.rotation.z;
	    this.camera.rotation.y = this.cameraSettings.rotation.y;

	    //this.controls = new OrbitControls(this.camera);

		//Bind methods
		this.lerpPosition = this.lerpPosition.bind(this);
		this.lerpRotation = this.lerpRotation.bind(this);
		this.update = this.update.bind(this);
		this.lookAt = this.lookAt.bind(this);
		this.backTo2D = this.backTo2D.bind(this);

		this.testOffset = 450.82660807878284;
	}

	update() {
		//this.fakeObj.position.set(new THREE.Euler ( 500.0,350,1150,"XYZ"));
		

		//let rotToSet = this.camera.quaternion.clone();
		//rotToSet.slerp(this.fakeObj.quaternion, 0.1);
		let oldQ = this.camera.quaternion.clone();
		let frontLookAtPos = this.lookAtPosition.clone();
		frontLookAtPos.x = this.camera.position.x;
		
		this.camera.lookAt(frontLookAtPos);		

		//Uncomment line below to reallow free camera rotation when "jumping" from cabin to next
		//this.camera.lookAt(this.lookAtPosition);		

		let target = this.camera.quaternion.clone();
		var qm = new THREE.Quaternion();
		THREE.Quaternion.slerp(oldQ, target, qm, 0.08);

		this.testOffset += 100;
		if (this.testOffset > 10000) {
			this.testOffset = 300;
		}

		//this.camera.quaternion = this.camera.quaternion;
		//this.camera.quaternion.normalize();

		//this.camera.updateProjectionMatrix();
		//this.camera.lookAt(this.lookAtPosition);
		//console.log(this.camera.quaternion);
		
		this.camera.position.lerp(this.targetPosition, 0.05);
		this.camera.quaternion.x = qm.x;
		this.camera.quaternion.y = qm.y;
		this.camera.quaternion.z = qm.z;
		this.camera.quaternion.w = qm.w;
		
	}

	backTo2D() {
		this.targetPosition = this.originalPosition.clone();
		this.lookAtPosition = new THREE.Vector3(50,400,0);
		this.positionState = "2D";
	}

	lookAt(obj) {
		if (!obj || !obj.position) {
			return;
		}

		let lookAtPos = new THREE.Vector3();

		obj.getWorldPosition(lookAtPos);
		console.log("world pos");
		console.log(lookAtPos);

		console.log("local pos");
		console.log(obj.position);
		//lookAtPos.x += 500;

		this.lookAtPosition = lookAtPos;
		this.lookAtPosition.x -= 15.0;
		//this.camera.quaternion.x += 0.1;


		this.targetPosition.x = lookAtPos.x;
		this.targetPosition.y = lookAtPos.y-100;
		this.targetPosition.z = 70;

		this.positionState = "Cabin";
/*
		this.camera.position.x = lookAtPos.x;
		this.camera.position.y = lookAtPos.y-100;
		this.camera.position.z = 100;
*/
		
	}

	zoomAt() {

	}

	lerpPosition() {

	}

	lerpRotation() {

	}


}

export default CabinSelectorCamera;