import * as THREE from "three";

//Common materials for all cabins
const highlightMaterial = new THREE.MeshBasicMaterial({
  color: "#00FF00",
  opacity: 0.3,
  transparent: true
});
const basicMaterial = new THREE.MeshBasicMaterial({
  color: "#00FF00",
  opacity: 0.2,
  transparent: true
});
const selectionMaterial = new THREE.MeshBasicMaterial({
  color: "#0000FF",
  opcaity: 0.75,
  transparent: true
});

/*
const highlightMaterial = new THREE.MeshBasicMaterial({
  color: "#00FF00",
  opacity: 0.3,
  transparent: true
});
const basicMaterial = new THREE.MeshBasicMaterial({
  color: "#7788FF",
  opacity: 0.3,
  transparent: true
});
const selectionMaterial = new THREE.MeshBasicMaterial({
  color: "#0000FF",
  opcaity: 0.75,
  transparent: true
});
*/
const cabinGeometry1 = new THREE.Geometry();
cabinGeometry1.vertices.push(
  new THREE.Vector3(0, 0, 0),
  new THREE.Vector3(10, 0, 0),
  new THREE.Vector3(0, -10, 0),
  new THREE.Vector3(10, -10, 0)
);
cabinGeometry1.faces.push(new THREE.Face3(0, 2, 1));
cabinGeometry1.faces.push(new THREE.Face3(1, 2, 3));
cabinGeometry1.computeBoundingSphere();

let nextGuestId = 1;

class CabinIndicator {
  constructor(
    scene,
    position,
    usedMesh,
    scale,
    cabinNumber,
    labelPositionOffset
  ) {
    this.scene = scene;
    var geometry = new THREE.BoxGeometry(7.5, 20, 0.5);
    this.mesh = new THREE.Mesh(usedMesh, basicMaterial);

    //Set position
    this.mesh.position.x = position.x;
    this.mesh.position.y = position.y;
    this.mesh.position.z = position.z;
    this.mesh.rotation.x = Math.PI / 2;
    this.mesh.scale.x = scale.x;
    this.mesh.scale.y = scale.y;
    this.mesh.scale.z = scale.z;

    this.mesh.name = "Guest Cabin " + cabinNumber;
    this.mesh.guestId = nextGuestId;
    nextGuestId++;

    let meshPosition = position.clone();
    if (labelPositionOffset) {
      meshPosition.add(labelPositionOffset);
    }
    this.mesh.labelPosition = meshPosition;
    this.mesh.cabinNumber = cabinNumber;
    scene.add(this.mesh);
  }

  serialize() {
    return {
      position: this.mesh.position,
      model: "box",
      scale: this.mesh.scale
    };
  }
}

export default CabinIndicator;
