import * as THREE from "three";
import CabinIndicator from './CabinIndicator';

class DeckPlane {
	constructor(scene, position, testTexture, cabinMeshes, deckBaseNumber) {
		this.scene = scene;
		this.deckBaseNumber = deckBaseNumber;
		var geometry = new THREE.PlaneGeometry( 1000, 200, 1000 );
		this.cabinMeshes = cabinMeshes;
		testTexture.flipY = true;
		testTexture.wrapS = THREE.RepeatWrapping;
		testTexture.repeat.x = 1;
		testTexture.wrapT = THREE.RepeatWrapping;
		testTexture.repeat.y = -1;

		var material = new THREE.MeshBasicMaterial({ 
			color: "#FFFFFF", metalness:0, flatShading: true, roughness:0,
			map: testTexture
		});

		this.plane = new THREE.Mesh( geometry, material );
		this.plane.position.y = position;
		scene.add( this.plane );

     	this.fillCabins = this.fillCabins.bind(this);
     	this.serializeCabinLayout = this.serializeCabinLayout.bind(this);

     	//Cabins
     	this.cabins = [];

     	this.cabinStartPosition = new THREE.Vector3(-412, 40, 0);
     	this.cabinTestWidth = 15.5;
     	this.singleWidth = 8.2;
     	this.doubleWidth = 15.55;
     	this.wideWidth = 11.5;
     	this.wideSplit = 23.3;
     	this.fillFirstCabins();
     	this.fillLastCabins();

     	this.serializeCabinLayout();
	}

	fillFirstCabins() {
		for (var i = 0; i < 36; i++) {
			var position = 0;
			var x = 0;

			if (i%2==0) {
				x = ( parseInt(i/2) * this.doubleWidth);
			} else {
				x = ( parseInt(i/2) * this.doubleWidth) + this.singleWidth;
			}
			if (i > 11) {
				x += 1.0;	
			}

			if (i > 29) {
				x += 0.7;	
			}

			position = new THREE.Vector3(x, this.plane.position.y, 0);
			if (i > 31) position.y += 4.0;
			position.add(this.cabinStartPosition);

			//Start paris 1-20
			this.cabins.push(
				new CabinIndicator(
					this.scene, 
					position,
					this.cabinMeshes[i%2],
					new THREE.Vector3( 0.8, 0.5, 0.85),
					(this.deckBaseNumber + i*2),
					new THREE.Vector3(0, 20, 0)
				)
			);

			
			let position2 = position.clone();
			position2.y -= 80;
			position2.x += 8.0;
			if (i > 31) position2.y -= 8.0;
			this.cabins.push(
				new CabinIndicator(
					this.scene, 
					position2,
					this.cabinMeshes[(i+1)%2],
					new THREE.Vector3( -0.8, 0.5, -0.85),
					(this.deckBaseNumber + i*2)+1,
					new THREE.Vector3(0, -20, 0)
				)
			);
		}
	}

	fillLastCabins() {
		for (var i = 0; i < 39; i++) {
			let labelOffset = new THREE.Vector3(0, 20, 0);
			var position = 0;
			var startX = 293.5 + this.wideSplit * parseInt(i/2);
			var x = startX;

			if (i%2 === 0) {
				x = startX - this.wideWidth;
				labelOffset.x += 4.5;
			} else {
				x = startX + this.wideWidth;
				labelOffset.x -= 6.0;
			}

			if (i > 21) {
				x -= 6.0;
			}


			if (i > 32) {
				x += 0.5;	
			}

			position = new THREE.Vector3(x, this.plane.position.y, 0);
			
			position.add(this.cabinStartPosition);

			if (i < 4 || (i < 30 && i > 25)) {
				position.y += 4.0;
			}

			var scale = new THREE.Vector3( 0.8, 0.5, 0.85);
			if (i%2 === 1) {
				scale.x = scale.x * -1;
			}

			let meshIndex = 2;
			if (i < 20 || (i > 21 && i < 34)) {
				meshIndex = 2;
			} else {
				scale = new THREE.Vector3( 0.8, 0.5, 0.85);
				switch (i) {
					case 20:
						position.x += 1.5;
						meshIndex = 0;
						break;
					case 21:
						position.x -= 13.0;
						meshIndex = 1;
						break;
					case 34:
						meshIndex = 0;
						break;
					case 35:
						position.x -= 15.0;
						meshIndex = 1;
						break;
					case 36:
						position.x -= (8.0);
						meshIndex = 0;
						break;
					case 37:
						position.x -= 23;
						meshIndex = 1;
						break;
					case 38:
						position.x -= 16.0;					
						meshIndex = 0;
						break;
				}
				
			}

			//Start paris 1-20
			this.cabins.push(
				new CabinIndicator(
					this.scene, 
					position,
					this.cabinMeshes[meshIndex],
					scale,
					(this.deckBaseNumber + i*2 + 72),
					labelOffset
				)
			);

			let position2 = position.clone();
			position2.y -= 80.0;

			if (i < 4 || (i < 30 && i > 25)) {
				position2.y -= 8.0;
			}

			let scale2 = scale.clone();
			scale2.z *= -1;

			let labelOffset2 = labelOffset.clone();
			labelOffset2.y *= -1;

			//Fast fix for wrong models showing up in the last cabins on stbd side
			if (i > 33 && i < 38) {
				meshIndex = meshIndex ? 0 : 1;	
				scale2.x *= -1;
				position2.x += 7.2;
			}

			this.cabins.push(
				new CabinIndicator(
					this.scene, 
					position2,
					this.cabinMeshes[meshIndex],
					scale2,
					(this.deckBaseNumber + i*2 + 72)+1,
					new THREE.Vector3(0, -20, 0)
				)
			);
		}

	}

	fillCabins() {
		for (var i = 0; i < 2; i++) {
			let position = new THREE.Vector3(i*this.cabinTestWidth,this.plane.position.y, 0);
			position.add(this.cabinStartPosition);
			
			this.cabins.push(
				new CabinIndicator(
					this.scene, 
					position,
					this.cabinMeshes[i%2]
				)
			);

			/*
			let position2 = new THREE.Vector3(i*this.cabinTestWidth,this.plane.position.y-60, 0);
			position2.add(this.cabinStartPosition);
			this.cabins.push(
				new CabinIndicator(
					this.scene, 
					position2,
					this.cabinMeshes[i%2]
				)
			);
			*/
		}
	}

	getCabinMeshes() {
		let cabinMeshes = [];
		for (let c of this.cabins) {
			cabinMeshes.push(c.mesh);
		}
		return cabinMeshes;
	}

	serializeCabinLayout() {
		var jsonData = {
			"name":"name",
			"cabins": []
		};

		for (let c of this.cabins) {
			jsonData.cabins.push(c.serialize());
		}

		//alert(JSON.stringify(jsonData));
	}
}

export default DeckPlane;