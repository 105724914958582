import React, { Component } from "react";
import MapSelector3D from "./modules/MapSelector3D";

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    console.log("rendering " + this.state.page);
    return <MapSelector3D />;
  }
}

export default App;
